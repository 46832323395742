<template>
  <div id="aldursrod">
        <h1>Röðin!</h1>
      <div v-if="ready">
         <b-field label="Veldu dagsetningu">
             (það er smá pikkles í ártalinu, ef maður vill fara meira en 10 ár fram í tímann þarf að gera það í nokkrum skrefum)
          <b-datepicker
              v-model = "birthday"
              placeholder="Smelltu til að velja..."
              locale="en-GB"
              icon="calendar-today"
              :min-date="minDate"
              :max-date="maxDate">
              trap-focus
              >
          </b-datepicker>
      </b-field>
        
      </div>
      <b>{{message}}</b>
      <p>{{message2}}</p>

      <b-table :data="ag_data" >
            <b-table-column field="age_group" label="Aldurshópur" v-slot="props">
                {{ props.row.age_group }}
            </b-table-column>
            <b-table-column field="total_in" label="fjöldi" numeric v-slot="props">
                {{ props.row.total_in }}
            </b-table-column>
            <b-table-column field="vaccination_rate" label="hlutfall bólusettra" numeric >
                <template v-slot:header="{ column }">
                <b-tooltip label="Gögn af covid.is" append-to-body dashed>
                   {{ column.label }}
                </b-tooltip>
            </template>
            <template v-slot="props">
                  {{ props.row.vaccination_rate }}
            </template>
              
            </b-table-column>
            <b-table-column field="vaccinated" label="bólusettir" numeric v-slot="props">
                {{ props.row.vaccinated }}
            </b-table-column>
            <b-table-column field="unvaccinated" label="óbólusettir" numeric v-slot="props">
                {{ props.row.unvaccinated }}
            </b-table-column>
        </b-table>
                <div> Dagsetning gagna {{data_date}}</div>
        <b-message >
           Þessar upplýsingar eru eingöngu settar fram til gamans, engin ákveðin röð hefur verið gefin út af landlækni, heilbrigðisráðuneyti eða öðrum aðilum.
           <br>
           Gögn um bólusetningar fengin af <a href="https://www.covid.is/tolulegar-upplysingar-boluefni"> covid.is</a>. 
           Þar sem  upplýsingar um bólusetningar aldursflokka innihalda bara hlutföll en ekki nákvæmar tölur, eru allar tölur um fjölda bólusettra 
           reiknaðar til baka úr hlutföllunum og fjölda einstaklina sem eru fæddir á því tímabili. Það er jafnframt ekki vitað við hvað landlæknir miðar þegar 
           aldur er flokkaður. Hér er miðað við aldur á degi upplýsingargjafar en líklega er flokkun hjá landlækni miðuð við einhvern fastan dag. <br>
           Til þess að finna að lokum hvar í röðinni þú ert þá er gert ráð fyrir því að bólusetningum sé jafn dreift innan þíns aldursflokk, en það er samt líklegra 
           að þeir sem eru í eldri kantinum í flokknum fái bólusetningu fyrst.
           <br>
           Að lokum það er bara miðað "bólusetning hafin", ekki skiptir máli hvort það er búið að fullbólusetja eða ekki.
        </b-message>
      </div>
</template>

<script>
import Vue from 'vue'
import { Table ,Datepicker, Field,Message, Tooltip} from 'buefy'
import 'buefy/dist/buefy.css'

//import AgeGroupItem from './AgeGroupItem.vue'
//Vue.use(AsyncComputed)
Vue.use(Table)
Vue.use(Datepicker)
Vue.use(Field)
Vue.use(Message)
Vue.use(Tooltip)


async function get_born_before(birthday,data) {

    let year = birthday.getFullYear();
    let first_of_year = new Date(year, 0, 0);
    let diff = birthday - first_of_year;
    let day = Math.floor(diff /  (1000 * 60 * 60 * 24));
    console.log('Day of year: ' + day);

    //let age =( Date.now() - birthday;
    let diff_ms = Date.now() - birthday.getTime();
    let age_dt = new Date(diff_ms); 
    let age = Math.abs(age_dt.getUTCFullYear() - 1970);
    
    console.log('age :' + age);
    let age_group=data.age_group_map[age];
    console.log('age_group :' + age_group);
    
    let ag_detail = data.age_group_data[age_group];

    /* let bl = await fetch('data/1912.bin').then(r => r.blob() );
    let bb = await bl.slice(360*3,361*3,'');
    let ba = bb.arrayBuffer();*/
    //var ab = await fetch('data/'+year+'.bin').then(r => r.arrayBuffer() ).catch((err) => { console.error(err); return });
    //var ab = await fetch('data/'+year+'.bin').catch((err) => { console.error(err); return });


    var ab = await fetch('data/'+year+'.bin').then(response => { 
        console.log("Server returned " + response.status + " : " + response.statusText);
            if(response.ok){
                return response.arrayBuffer()    
            } else{
              
                alert("Server returned " + response.status + " : " + response.statusText);
            }                
        })
 
    let dv = new DataView(ab);
    let b1=dv.getUint8(day*3);
    let b2=dv.getUint8(day*3+1);
    let b3=dv.getUint8(day*3+2);
    let result = b1 + 256*b2 + 65536*b3;

    let order_within_ag = result - ag_detail.total_older;
    let vacc_older_in_group =  Math.round(order_within_ag*(ag_detail.vaccination_rate.fully + ag_detail.vaccination_rate.started ))

    //alert( 'Hello, world!' + ab.byteLength +' ' + result  );
    return {
        born_before:result,
        vaccinated_older: ag_detail.vaccinated_older.started+ag_detail.vaccinated_older.fully+vacc_older_in_group
    }
}

export default {
  name: 'Aldursrod',
  props: {
    msg: String
  },
  data() {return  { age_group_map:{}, 
        age_group_data:{} ,
        message:'',
        ready:false,
        birthday:null,
        message2:'',
        data_date:null,
        minDate:new Date('1912-01-01'),
        maxDate:new Date('2021-12-31'),
        ag_data:[{
            age_group:0 ,
            total_in:0,
            vaccination_rate:0,
            vaccinated:0,
            unvaccinated:0
        }]
      }
  },
         asyncComputed: {
        // a computed getter
        message2: function () {
          // `this` points to the vm instance
          return get_born_before(this.birthday,this)
            .then(data =>  `Þú ert númer ${data.born_before - data.vaccinated_older} í röðinni! <br> ${data.born_before} eru fæddir á undan þér, þar af eru ${data.vaccinated_older} þegar bólusettir`
            )
          //return  `Þú ert númer ${this.birthday}`;
          //return this.message.split('').reverse().join('')
        }},
    created() {
        fetch("data/age_data.json", {
            "method": "GET"
        })
        .then(response => { 
            if(response.ok){
                return response.json()    
            } else{
                alert("Server returned " + response.status + " : " + response.statusText);
            }                
        })
        .then(data => {
            this.age_group_map = data.age_group_map;
            this.age_group_data = data.age_group_data;
            this.ag_data=[];
            let data_date = new Date(data.data_time*1000)
            this.data_date = `${data_date.getDate()}/${data_date.getMonth()+1}/${data_date.getFullYear()}`
            //data.age_group_data.forEach((value,key) => {
            for (const key in data.age_group_data ) {
                const value=data.age_group_data[key]
                this.ag_data.push({
                    age_group:key ,
                    total_in:value.total_in,
                    vaccination_rate: ((value.vaccination_rate.fully+value.vaccination_rate.started)*100).toFixed(1) + " %",
                    vaccinated:value.vaccinated_in.fully+value.vaccinated_in.started,
                    unvaccinated:value.total_in - (value.vaccinated_in.fully+value.vaccinated_in.started)
                })
                
            }
            this.ready = true;
            if ( this.$cookies.isKey('birthday') ) {   
                let bday = this.$cookies.get('birthday');
                if( !isNaN(bday)) {
                    let dt = new Date(parseInt(bday));
                    this.birthday = dt;
                }
            }

        })
        .catch(err => {
            console.log(err);
        })
    },
    watch: {
        birthday: function(value)  {
            let bb = value.getTime();
            this.$cookies.set('birthday',bb,'2m');
            //this.$cookies.set('birthday',1)   ;
            get_born_before(value,this)
                .then(data => {
                    this.message = `Þú ert númer ${data.born_before - data.vaccinated_older} í röðinni!`;
                    this.message2 =  `${data.born_before} eru fæddir á undan þér, þar af eru ${data.vaccinated_older} þegar bólusettir`;
            });
        }
    }

  /*components: {
    AgeGroupItem
  }*/
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.datepicker {
    width: 256px;
    margin: auto;
}
</style>
