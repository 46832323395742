<template>
  <div id="app">
    <img alt="Vaccine icon" src="./assets/vaccine.png" width="256" height="256">
    <Aldursrod msg="Welcome to Your Vue.js App"/>
  <footer>
 <small>&copy; Copyright 2021, Yngvi Þór Sigurjónsson (<a href="mail:blitzkopf@gmail.com">blitzkopf@gmail.com</a>)</small>
</footer> 
  </div>
</template>

<script>
import Aldursrod from './components/Aldursrod.vue'

export default {
  name: 'App',
  components: {
    Aldursrod
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
